"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LaunchDarklyService = void 0;
const launchdarkly_js_client_sdk_1 = require("launchdarkly-js-client-sdk");
const env_resolver_1 = require("./env-resolver");
class LaunchDarklyService {
    constructor(tokenService, logger, httpService) {
        this.tokenService = tokenService;
        this.logger = logger;
        this.httpService = httpService;
        this.featureToggleActive = async (key) => {
            await this.initialization();
            if (!this.ldClient)
                throw new Error('Failed to initialize launch darkly service');
            const defaultValue = false;
            const details = this.ldClient.variationDetail(key, defaultValue);
            if (details.reason !== null) {
                this.logger.warn({ message: `Failed to evaluate feature toggle for a reason: ${details.reason}. Returning default!` });
            }
            return details.value;
        };
        this.getWorkshopIds = async (userId) => {
            try {
                const response = await this.httpService.get(`${(0, env_resolver_1.getWorkshopUrlByEnv)()}/${userId}`);
                return response.data?.workshopDetails?.map(workshop => workshop.workshopId);
            }
            catch (error) {
                this.initializationFailed((error instanceof Error) ? error : new Error("Unknown error"));
                return [];
            }
        };
        this.initializationPromise = undefined;
    }
    initializationFailed(error) {
        this.initializationError = Promise.reject(error);
    }
    async initialization() {
        if (this.initializationPromise)
            return this.initializationPromise;
        return this.tokenService.userManager.getUser().then((user) => {
            if (!user) {
                this.logger.error({ message: `Feature toggling requires authenticated user` });
                return undefined;
            }
            return this.createLDUser(user).then(ldUser => {
                this.logger.debug({ message: `Initializing LD with user key: ${ldUser.key}` });
                this.ldUser = ldUser;
                this.ldClient = (0, launchdarkly_js_client_sdk_1.initialize)((0, env_resolver_1.getLaunchDarklyEnv)(), this.ldUser, { sendEvents: false, streaming: false });
                this.configEvents();
            });
        }).then((_) => {
            this.initializationPromise = new Promise(res => { this.initializationResolveFunction = res; });
            return this.initializationPromise;
        }).catch(() => {
            return undefined;
        });
    }
    configEvents() {
        this.ldClient?.on('failed', (error) => {
            this.initializationFailed(error);
        });
        this.ldClient?.on('ready', () => {
            this.initializationResolveFunction(this.initializationError ?? 'Ok');
        });
    }
    async createLDUser(user) {
        if (user === null) {
            const anon = { key: 'anon', name: 'anon', anonymous: true };
            return Promise.resolve(anon);
        }
        const workshops = await this.getWorkshopIds(user.profile.sub.toLowerCase());
        const ldUser = {
            firstName: user.profile.given_name,
            lastName: user.profile.family_name,
            key: user.profile.sub.toLowerCase(),
            custom: {
                workshops,
            },
        };
        return ldUser;
    }
}
exports.LaunchDarklyService = LaunchDarklyService;
